import {
  createTheme,
  responsiveFontSizes,
  adaptV4Theme,
} from '@mui/material/styles'
import { palette } from './palette'

// declare module "@mui/material/styles/createBreakpoints" {
//   interface BreakpointOverrides {
//     xxl: true;
//   }
// }

let theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 600, md: 960, lg: 1175, xl: 1920 },
  },
  palette,
  typography: {
    fontFamily: ['FS Meridian', 'sans-serif'].join(','),
    fontSize: 14,
    body1: {
      fontSize: 14,
      fontWeight: 400,
      color: '#000',
      lineHeight: '2em',
    },
    body2: {
      fontSize: 15,
      fontWeight: 500,
      color: '#000',
      lineHeight: '2em',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    h3: {
      fontSize: 48,
      fontWeight: 500,
    },
    h4: {
      fontSize: 36,
      fontWeight: 500,
    },
    h5: {
      fontSize: 32,
      fontWeight: 500,
    },
    h6: {
      fontSize: 22,
      fontWeight: 500,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&$disabled': {
            border: 0,
          },
        },
        contained: {
          width: '100%',
          borderRadius: 0,
          backgroundColor: '#000',
          color: '#fff !important',
          padding: '1rem 2rem',
          border: '1px solid transparent',
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            color: '#000  !important',
            backgroundColor: 'transparent',
            border: '1px solid #000',
            boxShadow: 'none',
          },
          '&:active': {
            color: '#fff  !important',
            border: '1px solid #000',
            boxShadow: 'none',
            backgroundColor: '#000',
          },
          '&:focus': {
            color: '#fff  !important',
            border: '1px solid #000',
            boxShadow: 'none',
            backgroundColor: '#000',
          },
        },
        outlined: {
          width: '100%',
          borderRadius: 0,
          // padding: "1rem 2rem",
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: '#000  !important',
          },
        },
      },
    },
    // TO Avoid auto Zoom in on mobile pjones...
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    // MuiGrid: {
    //   styleOverrides: {
    //     root: {
    //       marginLeft: '-2px',
    //     },
    //   },
    // },
  },
})

theme = responsiveFontSizes(theme)

export default theme

// === upgraded to V5 ====
// let theme = createTheme({
//   breakpoints: {
//     keys: ['xs', 'sm', 'md', 'lg', 'xl'],
//     values: { xs: 0, sm: 600, md: 960, lg: 1175, xl: 1920 },
//   },
//   palette,
//   typography: {
//     fontFamily: ['FS Meridian', 'sans-serif'].join(','),
//     fontSize: 14,
//     body1: {
//       fontSize: 14,
//       fontWeight: 400,
//       color: '#000',
//       lineHeight: '2em',
//     },
//     body2: {
//       fontSize: 15,
//       fontWeight: 500,
//       color: '#000',
//       lineHeight: '2em',
//     },
//     subtitle1: {
//       fontSize: 16,
//       fontWeight: 500,
//     },
//     subtitle2: {
//       fontSize: 16,
//       fontWeight: 400,
//       lineHeight: '1.5em',
//     },
//     h3: {
//       fontSize: 48,
//       fontWeight: 500,
//     },
//     h4: {
//       fontSize: 36,
//       fontWeight: 500,
//     },
//     h5: {
//       fontSize: 32,
//       fontWeight: 500,
//     },
//     h6: {
//       fontSize: 22,
//       fontWeight: 500,
//     },
//   },
//   components: {
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           borderRadius: 0,
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: 0,
//           '&$disabled': {
//             border: 0,
//           },
//         },
//         contained: {
//           width: '100%',
//           borderRadius: 0,
//           backgroundColor: '#000',
//           color: '#fff',
//           padding: '1rem 2rem',
//           border: '1px solid transparent',
//           boxShadow: 'none',
//           textTransform: 'none',
//           '&:hover': {
//             color: '#000',
//             backgroundColor: 'transparent',
//             border: '1px solid #000',
//             boxShadow: 'none',
//           },
//           '&:active': {
//             color: '#fff',
//             border: '1px solid #000',
//             boxShadow: 'none',
//             backgroundColor: '#000',
//           },
//           '&:focus': {
//             color: '#fff',
//             border: '1px solid #000',
//             boxShadow: 'none',
//             backgroundColor: '#000',
//           },
//         },
//         outlined: {
//           width: '100%',
//           borderRadius: 0,
//           // padding: "1rem 2rem",
//           textTransform: 'none',
//         },
//       },
//     },
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           borderRadius: 0,
//         },
//       },
//     },
//     MuiRadio: {
//       styleOverrides: {
//         colorSecondary: {
//           '&.Mui-checked': {
//             color: '#000',
//           },
//         },
//       },
//     },
//   },
// })

/// ==== OLD WORING =======

// let theme = createTheme({
//   breakpoints: {
//     keys: ['xs', 'sm', 'md', 'lg', 'xl'],
//     values: { xs: 0, sm: 600, md: 960, lg: 1175, xl: 1920 },
//   },
//   palette,
//   typography: {
//     fontFamily: ['FS Meridian', 'sans-serif'].join(','),
//     fontSize: 14,
//     body1: {
//       fontSize: 14,
//       fontWeight: 400,
//       color: '#000',
//       lineHeight: '2em',
//     },
//     body2: {
//       fontSize: 15,
//       fontWeight: 500,
//       color: '#000',
//       lineHeight: '2em',
//     },
//     subtitle1: {
//       fontSize: 16,
//       fontWeight: 500,
//     },
//     subtitle2: {
//       fontSize: 16,
//       fontWeight: 400,
//       lineHeight: '1.5em',
//     },
//     h3: {
//       fontSize: 48,
//       fontWeight: 500,
//     },
//     h4: {
//       fontSize: 36,
//       fontWeight: 500,
//     },
//     h5: {
//       fontSize: 32,
//       fontWeight: 500,
//     },
//     h6: {
//       fontSize: 22,
//       fontWeight: 500,
//     },
//   },
//   overrides: {
//     MuiOutlinedInput: {
//       root: {
//         borderRadius: 0,
//       },
//     },
//     MuiButton: {
//       root: {
//         borderRadius: 0,
//         '&$disabled': {
//           border: 0,
//         },
//       },
//       contained: {
//         width: '100%',
//         borderRadius: 0,
//         backgroundColor: '#000',
//         color: '#fff',
//         padding: '1rem 2rem',
//         border: '1px solid transparent',
//         boxShadow: 'none',
//         textTransform: 'none',
//         '&:hover': {
//           color: '#000',
//           backgroundColor: 'transparent',
//           border: '1px solid #000',
//           boxShadow: 'none',
//         },
//         '&:active': {
//           color: '#fff',
//           border: '1px solid #000',
//           boxShadow: 'none',
//           backgroundColor: '#000',
//         },
//         '&:focus': {
//           color: '#fff',
//           border: '1px solid #000',
//           boxShadow: 'none',
//           backgroundColor: '#000',
//         },
//       },
//       outlined: {
//         width: '100%',
//         borderRadius: 0,
//         // padding: "1rem 2rem",
//         textTransform: 'none',
//       },
//     },
//     MuiPaper: {
//       root: {
//         borderRadius: 0,
//       },
//     },
//     MuiRadio: {
//       colorSecondary: {
//         '&.Mui-checked': {
//           color: '#000',
//         },
//       },
//     },
//   },
// })
