import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { useCategoryContext } from '../../../context/pagenumberContext'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      slider: {
        display: 'flex',
        // TODO: remove justify, usefull only if we have 1/2 categories
        justifyContent: 'center',
        overflowX: 'scroll',

        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
          display: 'none' /* Safari and Chrome */,
        },

        [theme.breakpoints.down('lg')]: {
          justifyContent: 'start',
        },
      },
      card: {
        margin: '0 5px',
        width: '8rem',
        flexShrink: 0,
        backgroundColor: theme.palette.background.default,
      },
      cardContent: {
        // alignItems: "baseline",
        padding: theme.spacing(2, 0),
        backgroundColor: theme.palette.background.default,
      },
      cardActionAreaContainer: {
        backgroundColor: theme.palette.background.default,
      },
      media: {
        height: '5rem',
        backgroundSize: 'contain',
      },
      categoryName: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textAlign: 'center',
      },
    }),
  { name: 'MuiChildCategoriesSliderComponent' },
)

export default function ChildCategoriesSlider({
  permalink,
  fullPermalink,
  data,
}) {
  const classes = useStyles() as any
  const { t } = useTranslation(['common'])
  const categorySlug = t('category-slug')
  const { locale } = useCategoryContext()

  // ==== DYNAMIC OPTION TO BE MOVED TO GET STATIC PROPS...
  // const { loading, error, data } = useQuery(GET_CHILD_CATEGORIES, {
  //   variables: {
  //     permalink,
  //   },
  // });
  // if (loading) return <></>;
  // if (error) return <div>{error.message}</div>;

  // //* ======================================================
  // //* Once the data has come (we are after the "loading" check)
  // //* we want to run the check and get this item's profile
  // //* ======================================================
  // const categoryProfileIndex: number = getProfileIndexFromLanguage(
  //   data.findUniqueMetaInformation.category_profile,
  //   locale
  // );

  //staticProps fix
  const childCatData = data?.data ?? null

  // console.log('childCatData :>> ', childCatData)

  return (
    <>
      {childCatData.findUniqueMetaInformation.category_profile.category.child_categories
        .length !== 0 ? (
        <div className={classes.slider}>
          {childCatData?.findUniqueMetaInformation.category_profile.category.child_categories.map(
            (child, i) => {
              //* We need to make sure to get the correct profile for the children too
              const childcategory_profileIndex: number = getProfileIndexFromLanguage(
                child.category_profiles,
                locale,
              )

              if (
                childcategory_profileIndex != undefined ||
                childcategory_profileIndex != null
              ) {
                // console.log('child.id :>> ', child.id)
                return (
                  <Card
                    elevation={0}
                    square={true}
                    className={classes.card}
                    key={child.id}
                  // key={i}
                  >
                    <div className={classes.cardActionAreaContainer}>
                      <CardActionArea>
                        <Link
                          prefetch={false}
                          // href={{
                          //   pathname: `/${categorySlug}/${fullPermalink}/${child.category_profiles[childcategory_profileIndex].meta_information.permalink}/[slug]`,
                          //   query: {
                          //     slug: 1,
                          //   },
                          // }}

                          href={`/${categorySlug}/${fullPermalink}/${child?.category_profiles[childcategory_profileIndex]
                            ?.meta_information?.permalink ?? ''
                            }/1`}
                          locale={locale}
                        >
                          <a>
                            <CardMedia
                              className={classes.media}
                              image={
                                child.image_icon?.src_thumbnail ??
                                child.image_icon?.src_md ??
                                child.image_icon?.src ??
                                '/fallbacks/imageNotFound.png'
                              }
                            />
                          </a>
                        </Link>
                      </CardActionArea>
                    </div>
                    <CardContent className={classes.cardContent}>
                      <Link
                        prefetch={false}
                        // href={{
                        //   pathname: `/${categorySlug}/${fullPermalink}/${child.category_profiles[childcategory_profileIndex].meta_information.permalink}/[slug]`,
                        //   query: {
                        //     slug: 1,
                        //   },
                        // }}
                        href={`/${categorySlug}/${fullPermalink}/${child.category_profiles[childcategory_profileIndex].meta_information.permalink}/1`}
                        locale={locale}
                      >
                        <a className="cursorPointer">
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            component="h2"
                            className={classes.categoryName}
                          >
                            {
                              child?.category_profiles[
                                childcategory_profileIndex
                              ].name
                            }
                          </Typography>
                        </a>
                      </Link>
                    </CardContent>
                  </Card>
                )
              } else {
                return <p key={i}></p>
              }
            },
          )}
        </div>
      ) : null}
    </>
  )
}
